<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>
    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Categorías',
              quantity: data.counters.categories.total,
              icon: 'chess',
              change: data.counters.categories.actives,
              changeVar: 'categorías activas'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Academias',
              quantity: data.counters.academies.total,
              icon: 'shield-alt',
              change: data.counters.academies.actives,
              changeVar: 'equipos activos'
            }"
            bgColor="bg-red-1100"
          />
        </div>

        <!--<div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Jugadores',
              quantity: data.counters.players.total,
              icon: 'users',
              change: data.counters.players.actives,
              changeVar: 'jugadores activos'
            }"
            bgColor="bg-green-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Partidos',
              quantity: data.counters.games.total,
              icon: 'volleyball-ball',
              change: data.counters.games.actives,
              changeVar: 'partidos activos'
            }"
            bgColor="bg-orange-1100"
          />
        </div>-->
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row align-center">
          <!--<div class="my-auto mx-2">
            <searchbar
              placeholder="Buscar"
              v-model="query"
              @changeQuery="changeQuery"
            ></searchbar>
          </div>-->
          <t-input-group class="mx-2">
            <t-select
              :key="update"
              v-model="selector"
              :options="[
                { id: 1, name: 'Academias' },
                { id: 2, name: '11 Ideal' }
              ]"
              textAttribute="name"
              valueAttribute="id"
              @change="updateSelector"
            ></t-select>
          </t-input-group>
          <!--<t-input-group class="mx-2">
            <t-select
              v-model="parameters.id_subcategory"
              placeholder="Subcategoría"
              :options="subcategories"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>-->
        </div>

        <t-button
          v-if="selector == 2"
          class="my-auto mr-2 h-fit py-2"
          @click="
            $router.push(
              '/torneos/' +
                $route.params.id +
                '/categorias/' +
                $route.params.id2 +
                '/onceideal/crear'
            )
          "
        >
          Agregar 11 ideal
        </t-button>
      </div>

      <div v-if="selector == 1" class="w-full">
        <basic-table
          ref="list-table"
          module="tournamentAcademy"
          :headers="headers"
          :url="
            'torneos/' +
            $route.params.id +
            '/categorias/' +
            $route.params.id2 +
            '/academias'
          "
          :data="tournamentAcademies"
          :route="'/tournament_academy'"
          :tableName="'ACADEMIAS'"
          :parameters="parameters"
          :hasDetail="false"
          :canEdit="false"
          :canChangeStatus="false"
          @deactivate="deactivate"
          @activate="activate"
          @erase="erase"
        />
      </div>
      <div v-else class="w-full">
        <basic-table
          ref="list-table2"
          module="eleven"
          :headers="headers2"
          :url="
            'torneos/' +
            $route.params.id +
            '/categorias/' +
            $route.params.id2 +
            '/onceideal'
          "
          :data="elevens"
          :route="'/eleven'"
          :tableName="'11 IDEAL'"
          :parameters="parameters2"
          :hasDetail="false"
          :hasDetail5="true"
          :canChangeStatus="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
//import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard
    //Searchbar
  },
  data() {
    return {
      update: 0,
      loading: true,
      submitLoader: false,
      headers: [
        "",
        "Academia",
        "Coordinador",
        "Categoría",
        "Subcategoría",
        "Acciones"
      ],
      headers2: [
        "",
        "Nombre",
        "Categoría",
        "Subcategoría",
        "Registrado el",
        "Acciones"
      ],
      selector: this.$store.getters["tournamentAcademy/getSelector"]
        ? parseInt(this.$store.getters["tournamentAcademy/getSelector"])
        : 1,
      data: {
        counters: {
          academies: {},
          categories: {}
        }
      },
      query: "",
      parameters: {
        order_key: "id_category",
        order_value: "asc",
        id_tournament: "0",
        id_category: "0",
        id_subcategory: "0"
      },
      parameters2: {
        order_key: "id",
        order_value: "desc",
        id_tournament: "0",
        id_category: "0",
        id_subcategory: "0"
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    tournamentAcademies() {
      return this.$store.getters["global/getItems"](
        "tournamentAcademy",
        "elements"
      ).map((el) => ({
        id: el.id,
        img_logo: el.academy ? el.academy.url_logo : "",
        name: el.academy ? el.academy.name : "",
        coordinator: el.academy ? el.academy.coordinator : "",
        category: el.category ? el.category.name : "",
        subcategory: el.subcategory ? el.subcategory.name : "",
        active: el.active
      }))
    },
    elevens() {
      return this.$store.getters["global/getItems"]("eleven", "elements").map(
        (el) => ({
          id: el.id,
          img_logo: el.tournament ? el.tournament.url_logo : "",
          name: el.name,
          category: el.category ? el.category.name : "",
          subcategory: el.subcategory ? el.subcategory.name : "",
          datetime: el.created_at,
          active: el.active
        })
      )
    }
  },
  methods: {
    updateSelector() {
      this.$store.commit("tournamentAcademy/SET_SELECTOR", this.selector)
    },
    changeQuery(value) {
      if (this.$refs["list-table"]) this.$refs["list-table"].changeQuery(value)
    },
    getCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/tournament/" + this.$route.params.id + "/counters",
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/tournament_category",
          module: "tournamentCategory",
          id: this.$route.params.id2
        })
        .then((response) => {
          this.parameters.id_tournament = response.data.id_tournament
          this.parameters.id_category = response.data.id_category
          this.parameters.id_subcategory = response.data.id_subcategory

          this.parameters2.id_tournament = response.data.id_tournament
          this.parameters2.id_category = response.data.id_category
          this.parameters2.id_subcategory = response.data.id_subcategory
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deactivate() {
      this.data.counters.academies.actives--
    },
    activate() {
      this.data.counters.academies.actives++
    },
    erase() {
      this.data.counters.academies.total--
      this.data.counters.academies.actives--
    }
  },
  created() {
    this.getCounters()
    this.getItem()
  }
}
</script>
