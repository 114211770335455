var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"},[_c('t-loader',{attrs:{"extraClass":"border-primary-900 w-12 h-12"}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"pt-2"},[_c('div',{staticClass:"w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"},[_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Categorías',
            quantity: _vm.data.counters.categories.total,
            icon: 'chess',
            change: _vm.data.counters.categories.actives,
            changeVar: 'categorías activas'
          },"bgColor":"bg-yellow-1100"}})],1),_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Academias',
            quantity: _vm.data.counters.academies.total,
            icon: 'shield-alt',
            change: _vm.data.counters.academies.actives,
            changeVar: 'equipos activos'
          },"bgColor":"bg-red-1100"}})],1)]),_c('div',{staticClass:"flex flex-col md:flex-row align-center justify-between my-4"},[_c('div',{staticClass:"flex flex-col md:flex-row align-center"},[_c('t-input-group',{staticClass:"mx-2"},[_c('t-select',{key:_vm.update,attrs:{"options":[
              { id: 1, name: 'Academias' },
              { id: 2, name: '11 Ideal' }
            ],"textAttribute":"name","valueAttribute":"id"},on:{"change":_vm.updateSelector},model:{value:(_vm.selector),callback:function ($$v) {_vm.selector=$$v},expression:"selector"}})],1)],1),(_vm.selector == 2)?_c('t-button',{staticClass:"my-auto mr-2 h-fit py-2",on:{"click":function($event){return _vm.$router.push(
            '/torneos/' +
              _vm.$route.params.id +
              '/categorias/' +
              _vm.$route.params.id2 +
              '/onceideal/crear'
          )}}},[_vm._v(" Agregar 11 ideal ")]):_vm._e()],1),(_vm.selector == 1)?_c('div',{staticClass:"w-full"},[_c('basic-table',{ref:"list-table",attrs:{"module":"tournamentAcademy","headers":_vm.headers,"url":'torneos/' +
          _vm.$route.params.id +
          '/categorias/' +
          _vm.$route.params.id2 +
          '/academias',"data":_vm.tournamentAcademies,"route":'/tournament_academy',"tableName":'ACADEMIAS',"parameters":_vm.parameters,"hasDetail":false,"canEdit":false,"canChangeStatus":false},on:{"deactivate":_vm.deactivate,"activate":_vm.activate,"erase":_vm.erase}})],1):_c('div',{staticClass:"w-full"},[_c('basic-table',{ref:"list-table2",attrs:{"module":"eleven","headers":_vm.headers2,"url":'torneos/' +
          _vm.$route.params.id +
          '/categorias/' +
          _vm.$route.params.id2 +
          '/onceideal',"data":_vm.elevens,"route":'/eleven',"tableName":'11 IDEAL',"parameters":_vm.parameters2,"hasDetail":false,"hasDetail5":true,"canChangeStatus":false}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }